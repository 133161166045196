@import url('https://fonts.googleapis.com/css2?family=Sora&display=swap');

body {
    margin: 0 !important;
    background-color: #DCE1EE !important;
}

html, body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

* {
    outline: none !important;
}

.divColorTheme {
    color: #1C0098 !important;
}

.divBackgroundColor {
    background-color: #1C0098 !important;
}

.colorStyle {
    color: white;
}

.buttonStyle {
    background-color: #634DFF !important;
    color: white !important;
}

@media screen and (max-width: 1100px) {
    body {
        zoom: 0.53;
        transform-origin: top left;
    }
}
